<script>
import {defineComponent} from '@vue/composition-api'
import ImageInputDialog from './ImageInputDialog.vue'
import SingleImage from './SingleImage'

export default defineComponent({
  components : {
    SingleImage,
    ImageInputDialog
  },
  props : {
    type : {
      type : Number
    },
    title : {
      type    : String,
      default : ''
    },
    isRequired : {
      type    : Boolean,
      default : false
    },
    isViewOnly : {
      type    : Boolean,
      default : false
    },
    isError : {
      type    : String,
      default : null
    },
    getPreviewData : {
      type    : Function,
      default : Object
    },
    images : {
      type    : Array,
      default : () => []
    },
    errors : {
      type    : Object,
      default : () => {}
    },
  },
  data() {
    return {
      imageUploadDialog : false,
      imageIndex        : 0
    }
  },
  computed : {
  },
  methods : {
    openImageInputDialog(index) {
      console.log('openImageInputDialog:', index)
      if (this.isViewOnly) return
      this.imageIndex = index
      this.imageUploadDialog = true
    },
    closeImageInputDialog() {
      this.imageUploadDialog = false
      this.imageIndex = 0
    },
    updateImage(index) {
      console.log('updateImage: ', index)
    }
  }
})
</script>
<template>

<div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ title }}
      <em v-if="isRequired" class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <p v-if="!isViewOnly && type === 1" class="text-red">該当箇所の写真のアップロードをお願いします。該当箇所が隠れていて見えない場合、該当箇所がわからない場合はあけていただいて大丈夫です。</p>
    <p v-if="!isViewOnly && type !== 1" class="text-red">傷やへこみ、さびなど痛みのある場所、アピールしたい箇所をわかるように撮影し、写真のタイトルへ部位と説明を入力してください。</p>

    <div class="form-wrap-input"><p class="inps-err-txt">{{type === 1 ? (errors || {}).itemImageRequired : ''}}</p></div>
    <div v-bind:class="{'files-flex': true, 'align-end': true, 'err': !!isError}" >
      <div
        class="files-flex-list"
        v-for="(image, index) in images"
        :key="index"
      >
        <single-image
          :type="type"
          :imageIndex="index"
          :image="image"
          :openDialog="openImageInputDialog"
          :isViewOnly="isViewOnly"
          :getPreviewData="getPreviewData"
        />
      </div>
    </div>
  </div>

  <!-- 画像アップロードモーダル start -->
  <ImageInputDialog
    :imageIndex="imageIndex"
    :type="type"
    :isOpenDialog="imageUploadDialog"
    :closeDialog="closeImageInputDialog"
  />
  <!-- 画像アップロードモーダル end -->
</div>

</template>
<style lang="scss" scoped>
  .files-flex.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  #main .exhibit #entry-form .form-wrap-input .inps-err-txt {
    position: relative;
    margin-left: 10px;
    color: #f00
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>

<script>
import inspectionAuctionRequest from '@/stores/inspectionAuctionRequest'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const auctionRequestStore = inspectionAuctionRequest()

    return {
      auctionRequestStore
    }
  },
  components : {
  },
  props : {
    type : {
      type : Number
    },
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
    closeDialog : {
      type    : Function,
      default : Object
    },
    detailData : {
      type    : [],
      default : []
    }
  },
  data() {
    return {
      imageUploadDialog : false,
      imageIndex        : 0
    }
  },
  computed : {
  },
  methods : {
    requestAuction(index) {
      this.auctionRequestStore.requestAuction(index)
    }
  }
})
</script>
<template>
<v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
  <v-card style="border-radius: 1rem;">
    <v-card-text class="border-bottom mt-5 popup popup-fileup" style="display: block;">
        <div class="popup-wrap middle">
          <a class="popup-close js-close">
            <img @click.stop="closeDialog" src="@/assets/img/common/ic_close.svg" alt="">
          </a>
          <div class="popup-content">
            <h2>{{ $t('INSPECTION_AUCTION_POPUP_TITLE') }}</h2>
            <div class="popup-content-table">
              <table>
                <thead>
                  <tr>
                    <th style="width: 130px;">{{ $t('INSPECTION_AUCTION_POPUP_PREVIEW_START_DATETIME_FIELD') }}</th>
                    <th style="width: 322px;">{{ $t('INSPECTION_AUCTION_POPUP_BID_DATETIME_FIELD') }}</th>
                    <th>{{ $t('INSPECTION_AUCTION_POPUP_EXHIBITION_NAME_FIELD') }}</th>
                    <th style="width: 112px;">{{ $t('INSPECTION_AUCTION_POPUP_EXHIBITION_ITEM_COUNT_FIELD') }}</th>
                    <th>{{ $t('INSPECTION_AUCTION_POPUP_MAX_EXHIBITION_DATETIME_FIELD') }}</th>
                    <th class="only_pc_tablecell">{{ $t('INSPECTION_AUCTION_POPUP_CONTENT_FIELD') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in detailData">
                    <tr :key="i" v-bind:class="{'lt_blue': i%2 === 0, 'even_blue': i%2!==0}">
                      <td v-if="item.preview_start_datetime">{{item.preview_start_datetime}} ～</td>
                      <td v-else>{{$t('INSPECTION_AUCTION_POPUP_PREVIEW_DATETIME_NOT_SET')}}</td>
                      <td>{{item.start_datetime}} ～ {{item.end_datetime}} から随時終了</td>
                      <td>{{item.exhibition_name}}</td>
                      <td v-if="item.max_item_count === null" class="text-center">無制限</td>
                      <td v-else-if="item.exhibition_item_count === item.max_item_count" class="text-center">
                        {{item.exhibition_item_count}}/{{item.max_item_count}}
                        <div class="text-center" style="color: red;">締切</div>
                      </td>
                      <td v-else class="text-center">{{item.exhibition_item_count}}/{{item.max_item_count}}</td>
                      <td v-if="item.extend_flag === 1">あり<div>※最大 {{item.max_extend_datetime}} まで</div></td>
                      <td v-else>なし</td>
                      <td v-if="item.max_item_count === null" class="only_pc_tablecell"><a class="btn default orange" @click="requestAuction(i)">{{ $t('INSPECTION_AUCTION_POPUP_BUTTON') }}</a></td>
                      <td v-else-if="item.max_item_count > item.exhibition_item_count" class="only_pc_tablecell"><a class="btn default orange" @click="requestAuction(i)">{{ $t('INSPECTION_AUCTION_POPUP_BUTTON') }}</a></td>
                      <td v-else class="only_pc_tablecell"></td>
                    </tr>
                    <tr :key="`sp_${i}`" v-bind:class="{'sp_lt_blue': i%2 === 0, 'sp_even_blue': i%2!==0}">
                      <td colspan="5" class="only_sp_tablecell">
                        <a v-if="item.max_item_count === null" class="btn default orange" @click="requestAuction(i)">{{ $t('INSPECTION_AUCTION_POPUP_BUTTON') }}</a>
                        <a v-else-if="item.max_item_count > item.exhibition_item_count" class="btn default orange" @click="requestAuction(i)">{{ $t('INSPECTION_AUCTION_POPUP_BUTTON') }}</a>
                        <a v-else class="btn default orange"></a>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>

<template>
<main id="main" class="reminder">
  <div class="wrap1160">
    <div class="remind-msg-comp">
      <p v-html="$t('INSPECTION_REQUEST_FORM_COMPLETE_TEXT')"></p>
      <div class="remind-comp-btn">
        <a class="btnBsc-Black" @click="$routerGo($define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME)">{{$t('INSPECTION_LIST_FROM_TITLE')}}<img src="@/assets/img/common/ic_arrow_white.svg" alt="矢印"></a>
      </div>
    </div>
  </div>
</main>
</template>

<script>
import {defineComponent} from '@vue/composition-api';
export default defineComponent({
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  }
})
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>

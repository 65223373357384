module.exports = {
  // Check if objects are identical
  objectsAreIdentical(obj1, obj2) {
    if ((typeof obj1 === 'undefined' || obj1 === null)
      && (typeof obj2 === 'undefined' || obj2 === null)) {
      return true
    }
    if ((typeof obj1 === 'undefined' || obj1 === null)
      && (typeof obj2 !== 'undefined' && obj2 !== null)) {
      return false
    }
    if ((typeof obj2 === 'undefined' || obj2 === null)
      && (typeof obj1 !== 'undefined' && obj1 !== null)) {
      return false
    }
    return obj1.length === obj2.length && JSON.stringify(obj1) === JSON.stringify(obj2)
  },
  getFileName(item) {
    if (item) {
      const actualFileName = item.substring(item.lastIndexOf('/') + 1, item.length)
      return actualFileName
    }
    return ''
  },
  priceLocaleString(value, maxLength) {
    const arr = String(value).split('')
    const out = arr.filter(x => !isNaN(x))
    const num = out.join('')
    if (num === '') {
      return num
    }
    return maxLength ? Number(num.substring(0, maxLength)).toLocaleString() : Number(num).toLocaleString()
  },
  localeString2Number(value) {
    const arr = String(value).split('')
    const out = arr.filter(x => !isNaN(x))
    return Number(out.join(''))
  },
}

<template>
<div class="entry-formInner bgLGray">
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP1_REQUEST_NO') }}</p>
    <div class="form-wrap-input">
      <p>{{ manageNo || '' }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP1_REQUEST_TITLE') }}</p>
    <div class="form-wrap-input">
      {{ requestTitle }}
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP1_ESTIMATE_PRICE') }}</p>
    <div class="form-wrap-input">
      {{ commonData.estimatePrice | subComma | number2string('', '円') }}
    </div>
  </div>

  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('MEMBER_REQUEST_CONFIRM_NAME_LABEL') }}</p>
    <div class="form-wrap-input">
      {{ commonData.name }}
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('MEMBER_REQUEST_CONFIRM_TEL_LABEL') }}</p>
    <div class="form-wrap-input">
      {{ commonData.tel }}
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('MEMBER_REQUEST_CONFIRM_ADDRESS_LABEL') }}</p>
    <div class="form-wrap-input">
      {{ commonData.address }}
    </div>
  </div>

  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_STEP1_IDENTITY_VERIFY') }}</p>
    <div class="ipt-file ipt-file-box">
      <div v-if="commonData.identity_file === 0" class="ipt-file-txt">
        {{ (constants.SELECT_IDENTITY_FILE || []).filter(x => x.value === commonData.identity_file).map(x => x.label).join() }}
      </div>
      <div v-else-if="identityFiles.length === 0" class="ipt-file-txt">{{
        $t('INSPECTION_STEP1_FILE_NOT_SELECTED') }}</div>
      <div v-for="file in identityFiles" :key="file.name">{{ file.name }}</div>
    </div>
  </div>

  <item-images
    isViewOnly
    :type="1"
    class="fileupCol image-item"
    :title="$t('INSPECTION_STEP1_ITEM_IMAGES')"
    :getPreviewData="getPreviewData"
    :images="imageItems"
    :errors="errors"
    />
  <item-images
    isViewOnly
    :type="2"
    class="fileupAny image-item"
    :title="$t('INSPECTION_STEP1_OPTIONAL_IMAGES')"
    :getPreviewData="getPreviewData"
    :images="imageOptionals"
    :errors="errors"
    />

  <document-files
    class="mt-5"
    :viewOnly="true"
    :commonData="commonData"
    :documentFiles="documentFiles"
    :errors="errors"
  />

  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_MAKER') }}
    </p>
    <div class="form-wrap-input">
      {{ (constants.SELECT_MAKER || []).filter(x => x.value === commonData.maker).map(x => x.label).join() }}
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_BODY_TYPE') }}
    </p>
    <div class="form-wrap-input">
      <p
        v-for="typ in Object.keys(bodyTypes).map(x => bodyTypes[x] ? x : false).filter(x => x)"
        :key="typ"
      >
        {{ (constants.SELECT_BODY_TYPE || []).filter(x => String(typ) === String(x.value)).map(x => x.label).join() }}
      </p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_BIKE_TYPE') }}
    </p>
    <div class="form-wrap-input">
      {{ commonData.bike_type }}
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_FIRST_INSPECTION') }}
    </p>
    <div class="form-wrap-input">
        {{ (constants.SELECT_FUMEI_NASI || []).filter(x => x.value === commonData.first_inspection).map(x => x.label).join() }}
        <p v-if="commonData.first_inspection_other">{{ commonData.first_inspection_other }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
        {{ $t('INSPECTION_STEP2_INSPEC_EXPIRE') }}
        <br class="only_pc">
        {{ $t('INSPECTION_STEP2_INSPEC_EXPIRE_2') }}
    </p>
    <div class="form-wrap-input">
        {{ (constants.SELECT_FUMEI_NASI || []).filter(x => x.value === commonData.inspec_expire).map(x => x.label).join() }}
        <p v-if="commonData.inspec_expire_other">{{ commonData.inspec_expire_other }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_CUBIC_CAPACITY_1') }}
    </p>
    <div class="form-wrap-input">
        {{ (constants.SELECT_INPUT_OPTION || []).filter(x => x.value === commonData.cubic_capacity).map(x => x.label).join() }}
        <p v-if="commonData.cubic_capacity_other">{{ commonData.cubic_capacity_other | getCubicText}}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_MODEL') }}
    </p>
    <div class="form-wrap-input">
        {{ (constants.SELECT_INPUT_OPTION || []).filter(x => x.value === commonData.model).map(x => x.label).join() }}
        <p v-if="commonData.model_other">{{ commonData.model_other }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_FRAME_NO') }}
    </p>
    <div class="form-wrap-input">
        {{ (constants.SELECT_INPUT_OPTION || []).filter(x => x.value === commonData.frame_no).map(x => x.label).join() }}
        <p v-if="commonData.frame_no_other">{{ commonData.frame_no_other }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_ENGINE_TYPE_1') }}<br />
      {{ $t('INSPECTION_STEP2_ENGINE_TYPE_2') }}
    </p>
    <div class="form-wrap-input">
        {{ (constants.SELECT_INPUT_OPTION || []).filter(x => x.value === commonData.engine_type).map(x => x.label).join() }}
        <p v-if="commonData.engine_type_other">{{ commonData.engine_type_other }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_FUEL_TYPE') }}
    </p>
    <div class="form-wrap-input">
        {{ (constants.SELECT_FUEL_TYPE || []).filter(x => x.value === commonData.fuel_type).map(x => x.label).join() }}
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_MILEAGE') }}
    </p>
    <div class="form-wrap-input">
        {{ (constants.SELECT_INPUT_OPTION || []).filter(x => x.value === commonData.mileage).map(x => x.label).join() }}
        <p v-if="commonData.mileage_other">{{ commonData.mileage_other }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_COLOR') }}
    </p>
    <div class="form-wrap-input">
        {{ (constants.SELECT_INPUT_OPTION || []).filter(x => x.value === commonData.color).map(x => x.label).join() }}
        <p v-if="commonData.color_other">{{ commonData.color_other }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_METER') }}
    </p>
    <div class="form-wrap-input">
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_METER_SPEED') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_YES_NO || []).filter(x => x.value === commonData.meter_speed).map(x => x.label).join() }}
            <p v-if="commonData.meter_speed_other">{{ commonData.meter_speed_other }}</p>
        </div>
      </div>
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_METER_TACO') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_YES_NO_TACO || []).filter(x => x.value === commonData.meter_taco).map(x => x.label).join() }}
            <p v-if="commonData.meter_taco_other">{{ commonData.meter_taco_other }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_ENGINE_SERIAL') }}
      <span class="form-wrap-tit-cap">※わかる場合のみ記入</span>
    </p>
    <div class="form-wrap-input">
      {{ (constants.SELECT_INPUT_OPTION || []).filter(x => x.value === commonData.engine_serial).map(x => x.label).join() }}
      <p v-if="commonData.engine_serial_other">{{ commonData.engine_serial_other }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_HORN') }}
    </p>
    <div class="form-wrap-input">
      {{ (constants.SELECT_YES_NO || []).filter(x => x.value === commonData.horn).map(x => x.label).join() }}
      <p v-if="commonData.horn_other">{{ commonData.horn_other }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_HEAD_LIGHT') }}
    </p>
    <div class="form-wrap-input">
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_HEAD_LIGHT_HI') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_YES_NO || []).filter(x => x.value === commonData.headlight_hi).map(x => x.label).join() }}
          <p v-if="commonData.headlight_hi_other">{{ commonData.headlight_hi_other }}</p>
        </div>
      </div>
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_HEAD_LIGHT_LO') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_YES_NO || []).filter(x => x.value === commonData.headlight_lo).map(x => x.label).join() }}
          <p v-if="commonData.headlight_lo_other">{{ commonData.headlight_lo_other }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_WINKER') }}
    </p>
    <div class="form-wrap-input">
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_WINKER_FL') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_YES_NO || []).filter(x => x.value === commonData.winker_fl).map(x => x.label).join() }}
          <p v-if="commonData.winker_fl_other">{{ commonData.winker_fl_other }}</p>
        </div>
      </div>
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_WINKER_FR') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_YES_NO || []).filter(x => x.value === commonData.winker_fr).map(x => x.label).join() }}
          <p v-if="commonData.winker_fr_other">{{ commonData.winker_fr_other }}</p>
        </div>
      </div>
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_WINKER_RL') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_YES_NO || []).filter(x => x.value === commonData.winker_rl).map(x => x.label).join() }}
          <p v-if="commonData.winker_rl_other">{{ commonData.winker_rl_other }}</p>
        </div>
      </div>
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_WINKER_RR') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_YES_NO || []).filter(x => x.value === commonData.winker_rr).map(x => x.label).join() }}
          <p v-if="commonData.winker_rr_other">{{ commonData.winker_rr_other }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_TAIL_LIGHT') }}
    </p>
    <div class="form-wrap-input">
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_TAIL_LIGHT_SMALL') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_YES_NO || []).filter(x => x.value === commonData.tail_light_small).map(x => x.label).join() }}
          <p v-if="commonData.tail_light_small_other">{{ commonData.tail_light_small_other }}</p>
        </div>
      </div>
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_TAIL_LIGHT_BREAK') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_YES_NO || []).filter(x => x.value === commonData.tail_light_break).map(x => x.label).join() }}
          <p v-if="commonData.tail_light_break_other">{{ commonData.tail_light_break_other }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_BATTERY_HEALTH') }}
    </p>
    <div class="form-wrap-input">
      {{ (constants.SELECT_BATTERY_HEALTH || []).filter(x => x.value === commonData.battery_health).map(x => x.label).join() }}
      <p v-if="commonData.battery_health_other">{{ commonData.battery_health_other }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_RADIATOR_LEAK') }}
    </p>
    <div class="form-wrap-input">
        {{ (constants.SELECT_RADIATOR_LEAK || []).filter(x => x.value === commonData.radiator_leak).map(x => x.label).join() }}
        <p v-if="commonData.radiator_leak_other">{{ commonData.radiator_leak_other }}</p>
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_ENGINE_START_STATUS') }}
      <br class="only_pc">
      {{ $t('INSPECTION_STEP2_ENGINE_START_STATUS_2') }}
    </p>
    <div class="form-wrap-input">
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_WHITE_SMOKE') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_ARI_NASI || []).filter(x => x.value === commonData.engine_white_smoke).map(x => x.label).join() }}
          <p v-if="commonData.engine_white_smoke_other">{{ commonData.engine_white_smoke_other }}</p>
        </div>
      </div>
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_ENGINE_NOISE') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_ARI_NASI || []).filter(x => x.value === commonData.engine_noise).map(x => x.label).join() }}
          <p v-if="commonData.engine_noise_other">{{ commonData.engine_noise_other }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="form-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_FRONT_FORK_STATUS') }}
    </p>
    <div class="form-wrap-input">
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_FFORK_STRAIN') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_ARI_NASI || []).filter(x => x.value === commonData.front_fork_strain).map(x => x.label).join() }}
          <p v-if="commonData.front_fork_strain_other">{{ commonData.front_fork_strain_other }}</p>
        </div>
      </div>
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_RUSTY') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_ARI_NASI || []).filter(x => x.value === commonData.front_fork_rusty).map(x => x.label).join() }}
          <p v-if="commonData.front_fork_rusty_other">{{ commonData.front_fork_rusty_other }}</p>
        </div>
      </div>
      <div class="form-wrap-radioFlex">
        <span class="form-wrap-radioFlex-tit">{{ $t('INSPECTION_STEP2_OIL_LEAK') }}</span>
        <div class="form-wrap-radio">
          {{ (constants.SELECT_ARI_NASI || []).filter(x => x.value === commonData.front_fork_oil_leak).map(x => x.label).join() }}
          <p v-if="commonData.front_fork_oil_leak_other">{{ commonData.front_fork_oil_leak_other }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_HANDLE') }}
    </p>
    <div class="form-wrap-input">
      {{ (constants.SELECT_TEXT_AREA || []).filter(x => x.value === commonData.handle).map(x => x.label).join() }}
      <p style="white-space: pre-line" v-if="commonData.handle_other">{{ commonData.handle_other }}</p>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_FRONT_BREAK') }}
      <br>{{ $t('INSPECTION_STEP2_FRONT_BREAK_2') }}
      <br>{{ $t('INSPECTION_STEP2_FRONT_BREAK_3') }}
    </p>
    <div class="form-wrap-input">
      {{ (constants.SELECT_TEXT_AREA || []).filter(x => x.value === commonData.front_break).map(x => x.label).join() }}
      <p style="white-space: pre-line" v-if="commonData.front_break_other">{{ commonData.front_break_other }}</p>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_REAR_BREAK') }}
      <br>
      {{ $t('INSPECTION_STEP2_REAR_BREAK_2') }}
      <br>
      {{ $t('INSPECTION_STEP2_REAR_BREAK_3') }}
    </p>
    <div class="form-wrap-input">
      {{ (constants.SELECT_TEXT_AREA || []).filter(x => x.value === commonData.rear_break).map(x => x.label).join() }}
      <p style="white-space: pre-line" v-if="commonData.rear_break_other">{{ commonData.rear_break_other }}</p>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_ENGINE_CONDITION') }}
    </p>
    <div class="form-wrap-input">
      <p style="white-space: pre-line">{{ commonData.engine_condition }}</p>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_TIRE_CONDITION') }}
    </p>
    <div class="form-wrap-input">
      <p style="white-space: pre-line">{{ commonData.tire_condition }}</p>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_MODIFICATION') }}
    </p>
    <div class="form-wrap-input">
      <p style="white-space: pre-line">{{ commonData.modification }}</p>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_SCRATCH') }}
    </p>
    <div class="form-wrap-input">
      <p style="white-space: pre-line">{{ commonData.scratch }}</p>
    </div>
  </div>
  <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_OTHER_MEMO') }}
    </p>
    <div class="form-wrap-input">
      <p style="white-space: pre-line">{{ commonData.other_memo }}</p>
    </div>
  </div>
  <!-- <div class="textarea-wrap">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_STEP2_COMMENT') }}
    </p>
    <div class="form-wrap-input">
      <p style="white-space: pre-line" v-html="comment"></p>
    </div>
  </div> -->
</div>
</template>

<script>
import {defineComponent} from '@vue/composition-api'
import commonFilters from '../../../common/commonFilters'
import DocumentFiles from './DocumentFiles.vue'
import ItemImages from './ItemImages'
export default defineComponent({
  mixins     : [commonFilters],
  components : {
    ItemImages,
    DocumentFiles
  },
  props : {
    isPreview : {
      type    : Boolean,
      default : false
    },
    manageNo : {
      type    : String,
      default : null
    },
    requestTitle : {
      type    : String,
      default : null
    },
    comment : {
      type    : String,
      default : null
    },
    commonData : {
      type    : Object,
      default : () => {}
    },
    identityFiles : {
      type    : Array,
      default : () => []
    },
    bodyTypes : {
      type    : Object,
      default : () => {}
    },
    constants : {
      type    : Object,
      default : () => {}
    },
    getPreviewData : {
      type    : Function,
      default : Object
    },
    imageItems : {
      type    : Array,
      default : () => []
    },
    imageOptionals : {
      type    : Array,
      default : () => []
    },
    documentFiles : {
      type    : Array,
      default : () => []
    },
    errors : {
      type    : Object,
      default : () => {}
    },
  },
  computed : {
  },
  methods : {
  }
})
</script>

<style lang="scss" scoped>
.image-item {
  margin-top: 20px;
  margin-bottom: 20px;
}
form .form-wrap {
  align-items: start !important;
}
.selection-txt {
  margin-left: 10px;
}
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss">
</style>

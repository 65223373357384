import UploadFile from '@/api/uploadFile'
import Base from '@/common/base'
import i18n from '@/language/i18n'
import commonFilters from '../common/commonFilters'
// Import router from '@/router'
import _ from 'lodash'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useLoading from './useLoading'
const loadingStore = useLoading()

const initCommonData = {
  estimatePrice             : null,
  name                      : null,
  tel                       : null,
  address                   : null,
  authLetterFilePath        : [],
  identityFilePath          : [],
  documentFilePath          : [],
  identity_file             : null,
  document_file             : null,
  maker                     : null,
  bike_type                 : null,
  bike_type_other           : null,
  first_inspection          : null,
  first_inspection_other    : null,
  inspec_expire             : null,
  inspec_expire_other       : null,
  fuel_type                 : null,
  meter_speed               : null,
  meter_speed_other         : null,
  meter_taco                : null,
  meter_taco_other          : null,
  horn                      : null,
  horn_other                : null,
  headlight_hi              : null,
  headlight_hi_other        : null,
  headlight_lo              : null,
  headlight_lo_other        : null,
  winker_fl                 : null,
  winker_fl_other           : null,
  winker_fr                 : null,
  winker_fr_other           : null,
  winker_rl                 : null,
  winker_rl_other           : null,
  winker_rr                 : null,
  winker_rr_other           : null,
  tail_light_small          : null,
  tail_light_small_other    : null,
  tail_light_break          : null,
  tail_light_break_other    : null,
  battery_health            : null,
  battery_health_other      : null,
  radiator_leak             : null,
  radiator_leak_other       : null,
  engine_white_smoke        : null,
  engine_white_smoke_other  : null,
  engine_noise              : null,
  engine_noise_other        : null,
  front_fork_strain         : null,
  front_fork_strain_other   : null,
  front_fork_rusty          : null,
  front_fork_rusty_other    : null,
  front_fork_oil_leak       : null,
  front_fork_oil_leak_other : null,
  handle                    : null,
  handle_other              : null,
  front_break               : null,
  front_break_other         : null,
  rear_break                : null,
  rear_break_other          : null,
  engine_condition          : null,
  engine_condition_other    : null,
  tire_condition            : null,
  tire_condition_other      : null,
  modification              : null,
  modification_other        : null,
  scratch                   : null,
  scratch_other             : null,
  other_memo                : null,
  other_memo_other          : null,
  model                     : null,
  model_other               : null,
  frame_no                  : null,
  frame_no_other            : null,
  engine_type               : null,
  engine_type_other         : null,
  mileage                   : null,
  mileage_other             : null,
  color                     : null,
  color_other               : null,
  cubic_capacity            : null,
  cubic_capacity_other      : null,
  engine_serial             : null,
  engine_serial_other       : null,
}

const initItemImages = [
  {id : 1, title : '左', fileName : null, key : null, preview : null, label : '左'},
  {id : 2, title : '右', fileName : null, key : null, preview : null, label : '右'},
  {id : 3, title : '前', fileName : null, key : null, preview : null, label : '前'},
  {id : 4, title : '後', fileName : null, key : null, preview : null, label : '後'},
  {id : 5, title : 'メーター', fileName : null, key : null, preview : null, label : 'メーター'},
  {id : 6, title : 'タンク中', fileName : null, key : null, preview : null, label : 'タンク中'},
  {id : 7, title : 'エンジン左', fileName : null, key : null, preview : null, label : 'エンジン左'},
  {id : 8, title : 'エンジン右', fileName : null, key : null, preview : null, label : 'エンジン右'},
  {id : 9, title : 'エンジン前', fileName : null, key : null, preview : null, label : 'エンジン前'},
  {id : 10, title : 'エンジン番号', fileName : null, key : null, preview : null, label : 'エンジン番号'},
  {id : 11, title : 'シート', fileName : null, key : null, preview : null, label : 'シート'},
  {id : 12, title : '前方ホイール左', fileName : null, key : null, preview : null, label : '前方ホイール左'},
  {id : 13, title : '前方ホイール右', fileName : null, key : null, preview : null, label : '前方ホイール右'},
  {id : 14, title : '前方タイヤ', fileName : null, key : null, preview : null, label : '前方タイヤ'},
  {id : 16, title : '後方ホイール左', fileName : null, key : null, preview : null, label : '後方ホイール左'},
  {id : 17, title : '後方ホイール右', fileName : null, key : null, preview : null, label : '後方ホイール右'},
  {id : 15, title : '後方タイヤ', fileName : null, key : null, preview : null, label : '後方タイヤ'},
  {id : 19, title : 'リアサスペンション左', fileName : null, key : null, preview : null, label : 'リアサスペンション左'},
  {id : 18, title : 'リアサスペンション右', fileName : null, key : null, preview : null, label : 'リアサスペンション右'},
]

const initOptionalImages = [
  {id : 1, title : '', fileName : null, key : null, preview : null},
  {id : 2, title : '', fileName : null, key : null, preview : null},
  {id : 3, title : '', fileName : null, key : null, preview : null},
  {id : 4, title : '', fileName : null, key : null, preview : null},
  {id : 5, title : '', fileName : null, key : null, preview : null},
  {id : 6, title : '', fileName : null, key : null, preview : null},
  {id : 7, title : '', fileName : null, key : null, preview : null},
  {id : 8, title : '', fileName : null, key : null, preview : null},
  {id : 9, title : '', fileName : null, key : null, preview : null},
  {id : 10, title : '', fileName : null, key : null, preview : null}
]

const initDocumentFiles = [
  {id : 1, label : '車検証', key : null, file : null, fileName : null},
  {id : 2, label : '予備検査証', key : null, file : null, fileName : null},
  {id : 3, label : '廃車・返納・一時抹消証明書', key : null, file : null, fileName : null},
  {id : 4, label : '通関証明証', key : null, file : null, fileName : null},
]

const useInspection = defineStore('useInspection', {
  state : () => {
    return {
      // Locale: 'ja',
      item_request_no     : null,
      memberId            : null,
      manageNo            : null,
      requestTitle        : null,
      comment             : null,
      commonData          : _.cloneDeep(initCommonData),
      itemImages          : _.cloneDeep(initItemImages),
      optionalImages      : _.cloneDeep(initOptionalImages),
      documentFiles       : _.cloneDeep(initDocumentFiles),
      authLetterFiles     : [],
      identityFiles       : [],
      bodyTypes           : {},
      errors              : {},
      editFlag            : true,
      completedFlag       : false,
      ruleCheckBtnClicked : false,

      // Temporary
      saveTemporaryComplete     : false,
      itemRequestTemporary      : null,
      itemRequestFilesTemporary : null,

      // Constants
      constants                : {},

      // Data for change checking
      origRequestData : null,
    }
  },
  getters : {
    getBikeData : () => {
      return this.commonData
    }
  },
  actions : {
    memberCheck(itemRequestNo, locale = 'ja') {
      if (itemRequestNo) {
        return Methods.apiExcute('private/check-member-for-request-item', locale, {
          item_request_no : itemRequestNo
        }).then(data => {
          if (data) {
            if (data[0].member_flag === true) {
              return Promise.resolve()
            }
          }
          return Promise.reject(new Error('Invalid page request'))
        })
      }
      return Promise.resolve()
    },
    resetData() {
      console.log('resetData')
      this.item_request_no = null
      this.memberId = null
      this.manageNo = null
      this.requestTitle = null
      this.comment = null
      this.constants = {}
      this.commonData = _.cloneDeep(initCommonData)
      this.bodyTypes = JSON.parse(`{${(this.constants.SELECT_BODY_TYPE || []).map(x => `"${x.value}":false`).join(',')}}`)
      this.itemImages = _.cloneDeep(initItemImages)
      this.optionalImages = _.cloneDeep(initOptionalImages)
      this.documentFiles = _.cloneDeep(initDocumentFiles)
      this.authLetterFiles = []
      this.identityFiles = []
      this.errors = {}
      this.editFlag = true
      this.completedFlag = false
      this.ruleCheckBtnClicked = false

      this.saveTemporaryComplete = false
      this.itemRequestTemporary = null
      this.itemRequestFilesTemporary = null

      // Data for change checking
      this.origRequestData = null
    },
    getConstantFromServer(itemRequestNo, locale) {
      if (itemRequestNo) {
        // Editing mode
        return Methods.apiExcute('private/get-request-item-preview-constants', locale, {
          item_request_no : itemRequestNo
        })
      } else {
        // New Creating mode
        return Methods.apiExcute('private/get-request-item-constants', locale)
      }
    },
    getConstants(itemRequestNo, locale = 'ja') {
      console.log('getConstants')
      loadingStore.setLoading(true)
      this.item_request_no = itemRequestNo
      this.getConstantFromServer(itemRequestNo, locale).then(data => {
        // LoadingStore.setLoading(false)
        this.constants = {}
        data.constants.forEach(row => {
          switch (row.key_string) {
          case 'SELECT_MAKER':
            if (!this.constants[row.key_string]) {
              this.constants[row.key_string] = []
            }
            if (row.value3) {
              this.constants[row.key_string].push({
                header      : row.value3,
                divider     : true,
              })
            }
            this.constants[row.key_string].push({
              value       : Number(row.value1),
              label       : row.value2,
            })
            break
          case 'DOCUMENT_FILES':
          case 'SELECT_YES_NO':
          case 'SELECT_FUEL_TYPE':
          case 'SELECT_BATTERY_HEALTH':
          case 'SELECT_RADIATOR_LEAK':
          case 'SELECT_ARI_NASI':
          case 'SELECT_TEXT_AREA':
          case 'SELECT_BODY_TYPE':
          case 'SELECT_INPUT_OPTION':
          case 'SELECT_IDENTITY_FILE':
          case 'SELECT_YES_NO_TACO':
          case 'SELECT_FUMEI_NASI':
            if (!this.constants[row.key_string]) {
              this.constants[row.key_string] = []
            }
            this.constants[row.key_string].push({
              value       : Number(row.value1),
              label       : row.value2,
              value3      : row.value3,
              isTextInput : String(row.value3) === '1'
            })
            break
          default:
            break
          }
        })

        this.memberId = data.memberId

        // Saved temporary data
        if (this.memberId) {
          this.itemRequestTemporary = data.requestDetail
          this.itemRequestFilesTemporary = data.requestFiles

          this.manageNo = data.requestDetail.manage_no

          // Common data
          if (this.itemRequestTemporary) {
            this.commonData = _.cloneDeep(Object.assign(
              {},
              initCommonData,
              this.itemRequestTemporary.item_free_field ?? {},
              {
                name    : this.itemRequestTemporary.member_free_field ? this.itemRequestTemporary.member_free_field.name : null,
                tel     : this.itemRequestTemporary.member_free_field ? this.itemRequestTemporary.member_free_field.tel : null,
                address : this.itemRequestTemporary.member_free_field ? this.itemRequestTemporary.member_free_field.address : null,
              },
            ))
            this.requestTitle = _.clone(this.itemRequestTemporary.title)
            this.comment = _.clone(this.itemRequestTemporary.comment)

            // Saved document files
            if (this.commonData && this.commonData.documentFilePath) {
              this.documentFiles.map(doc => {
                const savedDoc = this.commonData.documentFilePath.find(x => String(x.id) === String(doc.id))
                if (savedDoc) {
                  doc.key = savedDoc.key
                  doc.fileName = Base.getFileName(savedDoc.key)
                }
              })
            }
            // Saved identity file
            if (this.commonData && this.commonData.identityFilePath && this.commonData.identityFilePath.length > 0) {
              const identityS3 = this.commonData.identityFilePath[0]
              this.identityFiles.push({key : identityS3, name : Base.getFileName(identityS3), file : null})
            }
          }

          // Member info
          if (!this.commonData.name) this.commonData.name = _.clone(data.member.name)
          if (!this.commonData.tel) this.commonData.tel = _.clone(data.member.tel)
          if (!this.commonData.address) this.commonData.address = _.clone(data.member.address)

          // Body types
          if (this.commonData.body_types) {
            const tmpBdTyp = (this.constants.SELECT_BODY_TYPE || []).map(x => {
              const typ = this.commonData.body_types.find(y => String(y) === String(x.value))
              if (typ) return `"${x.value}":true`
              return `"${x.value}":false`
            })
            this.bodyTypes = JSON.parse(`{${tmpBdTyp.join(',')}}`)
          }

          // Saved item images
          if (this.itemRequestFilesTemporary) {
            this.itemImages.map(img => {
              const savedImg = this.itemRequestFilesTemporary
                .find(x => String(x.division) === '1' && String(x.physical_name) === String(img.id))
              if (savedImg) {
                img.key = savedImg.file_path
                img.title = savedImg.file_title
                img.fileName = savedImg.file_path
                img.preview = null
              }
            })
            // Saved optional images
            this.optionalImages.map(img => {
              const savedImg = this.itemRequestFilesTemporary
                .find(x => String(x.division) === '2' && String(x.physical_name) === String(img.id))
              if (savedImg) {
                img.key = savedImg.file_path
                img.title = savedImg.file_title
                img.fileName = savedImg.file_path
                img.preview = null
              }
            })
          }
        }

        // Update original data for change-checking
        this.origRequestData = _.cloneDeep({
          requestTitle        : this.requestTitle,
          comment             : this.comment,
          commonData          : this.commonData,
          itemImages          : this.itemImages,
          optionalImages      : this.optionalImages,
          documentFiles       : this.documentFiles,
          identityFiles       : this.identityFiles,
          bodyTypes           : this.bodyTypes
        })

        loadingStore.setLoading(false)
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    getPreviewData(type, index, locale = 'ja') {
      console.log('getPreviewData: ', type, index, locale)
      const image = type === 1 ? this.itemImages[index] : this.optionalImages[index]
      if (!image || !image.key || image.preview) {
        return
      }
      Methods.apiExcute('private/download-file', locale, {key : image.key})
        .then(url => {
          console.log('url: ', url)
          image.preview = url
        })
    },
    checkInputFormat() {
      const tags = [
        'requestTitle',
        'estimatePrice',
        'name',
        'address',
        'tel',
      ]
      // Const pattern = new RegExp('^[0-9a-zA-Z -/:-@\[-~]+$')
      const jpPattern = new RegExp('^([^\x01-\x7E]|[0-9a-zA-Z -/:-@\[-~])+$')
      this.errors = {}
      for (const tag of tags) {
        if (this.commonData[tag] && !jpPattern.test(this.commonData[tag])) {
          this.errors[tag] = i18n.t('TEXT_FORMAT_ERROR')
        }
      }
      if (this.commonData.email !== this.commonData.confirmEmail) {
        this.errors.confirmEmail = i18n.t('MAIL_CONFIRM_ERROR_MESSAGE')
      }
    },
    validate(locale = 'ja') {
      // Escape html
      this.comment = commonFilters.filters.escapeHtml(this.comment)
      this.commonData.handle_other = commonFilters.filters.escapeHtml(this.commonData.handle_other)
      this.commonData.front_break_other = commonFilters.filters.escapeHtml(this.commonData.front_break_other)
      this.commonData.rear_break_other = commonFilters.filters.escapeHtml(this.commonData.rear_break_other)
      this.commonData.engine_condition = commonFilters.filters.escapeHtml(this.commonData.engine_condition)
      this.commonData.tire_condition = commonFilters.filters.escapeHtml(this.commonData.tire_condition)
      this.commonData.modification = commonFilters.filters.escapeHtml(this.commonData.modification)
      this.commonData.scratch = commonFilters.filters.escapeHtml(this.commonData.scratch)
      this.commonData.other_memo = commonFilters.filters.escapeHtml(this.commonData.other_memo)

      const params = {
        validateFlag   : true,
        requestTitle   : this.requestTitle,
        comment        : this.comment,
        freeField      : Object.assign(
          {},
          this.commonData,
          {
            body_types       : Object.keys(this.bodyTypes).filter(x => this.bodyTypes[x]),
            documentFilePath : this.documentFiles.map(x => {
              return {
                id  : x.id,
                key : x.key || (x.file ? x.file.name : null)
              }
            })
          }
        ),
        itemImages     : this.itemImages.filter(y => y.key).map(x => _.omit(x, 'file', 'preview')),
        optionalImages : this.optionalImages.filter(y => y.key).map(x => _.omit(x, 'file', 'preview')),
        documentFiles  : this.documentFiles.filter(y => y.key).map(x => _.omit(x, 'file', 'preview', 'fileName')),
      }
      this.errors = {}
      this.checkInputFormat()
      loadingStore.setLoading(true)
      return Methods.apiExcute('private/request-item', locale, params).then(() => {
        if (Object.keys(this.errors).length === 0) {
          return this.uploadFiles()
            .then(() => {
              loadingStore.setLoading(false)
              this.editFlag = false
              window.scrollTo({
                top      : 0,
                left     : 0,
                behavior : 'smooth'
              })
              return Promise.resolve()
            })
            .catch(error => {
              loadingStore.setLoading(false)
              console.log('アップロード失敗', error)
              return Promise.resolve()
            })
        } else {
          loadingStore.setLoading(false)
          return Promise.resolve()
        }
      })
    },
    requestItem(locale = 'ja') {
      const params = {
        item_request_no : this.item_request_no,
        requestTitle    : this.requestTitle,
        comment         : this.comment,
        freeField       : Object.assign(
          {},
          this.commonData,
          {
            body_types       : Object.keys(this.bodyTypes).filter(x => this.bodyTypes[x]),
            documentFilePath : this.documentFiles.map(x => {
              return {
                id  : x.id,
                key : x.key
              }
            })
          }
        ),
        itemImages     : this.itemImages.filter(y => y.key).map(x => _.omit(x, 'file', 'preview')),
        optionalImages : this.optionalImages.filter(y => y.key).map(x => _.omit(x, 'file', 'preview')),
        documentFiles  : this.documentFiles.filter(y => y.key).map(x => _.omit(x, 'file', 'preview', 'fileName')),
      }
      loadingStore.setLoading(true)
      return Methods.apiExcute('private/request-item', locale, params).then(data => {
        loadingStore.setLoading(false)
        this.completedFlag = true
        window.scrollTo({
          top      : 0,
          left     : 0,
          behavior : 'smooth'
        })
        return Promise.resolve()
      })
    },
    saveTemporary(locale = 'ja') {
      loadingStore.setLoading(true)
      return Promise.resolve()
        .then(() => {
          // Upload file
          return this.uploadFiles()
        })
        .then(() => {
          // Save temporary
          const params = {
            requestTitle   : this.requestTitle,
            comment        : this.comment,
            freeField      : Object.assign(
              {},
              this.commonData,
              {
                body_types       : Object.keys(this.bodyTypes).filter(x => this.bodyTypes[x]),
                documentFilePath : this.documentFiles.map(x => {
                  return {
                    id  : x.id,
                    key : x.key
                  }
                })
              }
            ),
            itemImages     : this.itemImages.filter(y => y.key).map(x => _.omit(x, 'file', 'preview')),
            optionalImages : this.optionalImages.filter(y => y.key).map(x => _.omit(x, 'file', 'preview')),
            documentFiles  : this.documentFiles.filter(y => y.key).map(x => _.omit(x, 'file', 'preview', 'fileName')),
          }
          return Methods.apiExcute('private/request-item-temporary', locale, params)
        })
        .then(data => {
          loadingStore.setLoading(false)
          this.saveTemporaryComplete = true
          return Promise.resolve()
        })
    },
    // Type: 1 itemImages, 2 optionalImages
    clearImage(index, type, tempImage) {
      const image = type === 1 ? this.itemImages[index] : this.optionalImages[index]
      if (!image) {
        return Promise.resolve()
      }
      if (image.key || image.file) {
        image.key = null
        image.file = null
        image.fileName = null
        image.preview = null
        if (type === 2) {
          image.title = ''
        }
      }
      return Promise.resolve()
    },
    // Type: 1 itemImages, 2 optionalImages
    uploadItemImage(index, type, tempImage) {
      const image = type === 1 ? this.itemImages[index] : this.optionalImages[index]
      if (!image) {
        return Promise.resolve()
      }
      // Update title only
      if (tempImage.key) {
        Object.assign(image, {
          title    : tempImage.title,
        })
        return Promise.resolve()
      }
      // If no file selected then exit
      if (!tempImage.file) {
        return Promise.resolve()
      }
      return Promise.resolve()
        .then(() => {
          return UploadFile.getUploadCredentials('item-request')
        })
        .then(credentials => {
          return UploadFile.upload(credentials, tempImage.file)
            .then(result => {
              Object.assign(image, {
                key      : result.Key,
                fileName : tempImage.fileName,
                title    : tempImage.title,
                preview  : tempImage.preview
              })
              return Promise.resolve(result.Key)
            })
        })
    },
    uploadFiles() {
      return Promise.resolve()
        .then(() => {
          if (this.authLetterFiles.filter(file => !file.key).length > 0
           || this.identityFiles.filter(file => !file.key).length > 0
           || this.documentFiles.filter(file => file.file && !file.key).length > 0) {
            return UploadFile.getUploadCredentials('item-request')
          } else {
            return Promise.resolve({})
          }
        })
        .then(credentials => {
          return Promise.all([
            Promise.all(this.authLetterFiles.filter(file => !file.key).map((file, index) => {
              const s3access = credentials
                ? Object.assign({}, credentials, {
                  prefix_key : `${credentials.prefix_key}/authLetterFiles/${index}`
                })
                : {}
              return UploadFile.upload(s3access, file.file)
                .then(result => {
                  file.key = result.Key
                  return Promise.resolve(result.Key)
                })
            })),
            Promise.all(this.identityFiles.filter(file => !file.key).map((file, index) => {
              const s3access = credentials
                ? Object.assign({}, credentials, {
                  prefix_key : `${credentials.prefix_key}/identityFiles/${index}`
                })
                : {}
              return UploadFile.upload(s3access, file.file)
                .then(result => {
                  file.key = result.Key
                  return Promise.resolve(result.Key)
                })
            })),
            Promise.all(this.documentFiles.filter(file => file.file && !file.key).map((file, index) => {
              const s3access = credentials
                ? Object.assign({}, credentials, {
                  prefix_key : `${credentials.prefix_key}/documentFiles/${index}`
                })
                : {}
              return UploadFile.upload(s3access, file.file)
                .then(result => {
                  file.key = result.Key
                  return Promise.resolve(result.Key)
                })
            })),
          ])
            .then(result => {
              this.commonData.authLetterFilePath = this.authLetterFiles.length > 0 ? this.authLetterFiles.map(file => file.key) : null
              this.commonData.identityFilePath = this.identityFiles.length > 0 ? this.identityFiles.map(file => file.key) : null
              this.commonData.documentFilePath = this.documentFiles.length > 0
                ? this.documentFiles.map(doc => {
                  return {
                    id  : doc.id,
                    key : doc.key
                  }
                })
                : null
            })
        })
    },
    searchPostCode(locale = 'ja') {
      const params = {
        postCode : this.commonData.postCode
      }
      loadingStore.setLoading(true)
      Methods.apiExcute('search-post-code', locale, params).then(data => {
        loadingStore.setLoading(false)
        this.commonData.address = data.address1 + data.address2 + data.address3
        this.commonData = Object.assign({}, this.commonData)
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    addIdentityFile(file, errMsg) {
      if (file.target.files.length > 0) {
        if (this.checkFileSize(file.target.files[0], errMsg)) {
          this.errors = Object.assign({}, this.errors, {identityFilePath : this.checkFileSize(file.target.files[0])})
        } else {
          this.identityFiles.push({
            file : file.target.files[0],
            name : file.target.files[0].name,
            key  : null
          })
          this.commonData.identityFilePath.push(file.target.files[0].name)
        }
      }
    },
    addAuthLetterFile(file, errMsg) {
      if (file.target.files.length > 0) {
        if (this.checkFileSize(file.target.files[0], errMsg)) {
          this.errors = Object.assign({}, this.errors, {authLetterFilePath : this.checkFileSize(file.target.files[0])})
        } else {
          this.authLetterFiles.push({
            file : file.target.files[0],
            name : file.target.files[0].name,
            key  : null
          })
          this.commonData.authLetterFilePath.push(file.target.files[0].name)
        }
      }
    },
    addDocumentFile(file, idx, errMsg) {
      if (file.target.files.length > 0) {
        if (this.checkFileSize(file.target.files[0], errMsg)) {
          const tmp = {}
          tmp[`documentFilePath${idx}`] = this.checkFileSize(file.target.files[0])
          this.errors = Object.assign({}, this.errors, tmp)
        } else {
          const doc = this.documentFiles.find(x => x.id === idx)
          if (doc) {
            doc.file = file.target.files[0]
            doc.fileName = file.target.files[0].name
            doc.key = null
          }
        }
      }
    },
    checkFileSize(file, errMsg = '') {
      if (file && file.size) {
        // 制限が5MBにする
        if (file.size > 5 * 1024 * 1024) {
          return errMsg
        }
      }
      return null
    },
    ruleCheckClick() {
      // 更新ボタンの活性・非活性
      if (document.getElementById('rule-chk').checked === false) {
        console.log('uncheck')
        this.ruleCheckBtnClicked = false
      } else {
        console.log('checked')
        this.ruleCheckBtnClicked = true
      }
    },
    onChangeModelValue({val, isTextInput}, column) {
      console.log('val, isTextInput: ', val, isTextInput)
      this.errors[column] = null
      this.commonData[column] = val
      if (!isTextInput) this.commonData[`${column}_other`] = null
    },
    isDataChanged() {
      loadingStore.setLoading(true)
      const current = _.cloneDeep({
        requestTitle        : this.requestTitle,
        comment             : this.comment,
        commonData          : this.commonData,
        itemImages          : this.itemImages,
        optionalImages      : this.optionalImages,
        documentFiles       : this.documentFiles,
        identityFiles       : this.identityFiles,
        bodyTypes           : this.bodyTypes
      })
      if (Base.objectsAreIdentical(this.origRequestData, current)) {
        loadingStore.setLoading(false)
        return false
      } else {
        loadingStore.setLoading(false)
        return true
      }
    },
    deleteRequestTemporary(locale = 'ja') {
      loadingStore.setLoading(true)
      return Methods.apiExcute('private/delete-request-item-temporary', locale, {})
        .then(data => {
          loadingStore.setLoading(false)
          return Promise.resolve()
        })
    }
  },
})

export default useInspection

<template>
<div class="entry-formInner bgLGray">
  <div v-if="judgeStatusPreview === true" class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_RESULT_JUDGE_STATUS') }}</p>
    <div class="form-wrap-input">
      {{ requestResultData.judge_status_name }}
    </div>
  </div>
  <div v-if="requestResultData.request_category_id === '1' && requestResultData.judge_status === 1" class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_RESULT_ASSESSMENT_AMOUNT') }}</p>
    <div class="form-wrap-input">
      {{ $options.filters.addComma(requestResultData.assessment_amount) + ' 円' }}
    </div>
  </div>
  <div class="form-wrap">
    <p class="form-wrap-tit">{{ $t('INSPECTION_RESULT_COMMENT') }}</p>
    <div class="form-wrap-input" style="white-space: pre-line">
      {{ requestResultData.judge_note }}
    </div>
  </div>
</div>
</template>

<script>
import CommonFilters from '@/common/commonFilters';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  components : {
  },
  mixins : [CommonFilters],
  props  : {
    isPreview : {
      type    : Boolean,
      default : false
    },
    requestResultData : {
      type    : Object,
      default : () => {}
    },
    judgeStatusPreview : {
      type    : Boolean,
      default : false
    }
  },
  computed : {
  },
  methods : {
  }
})
</script>

<style lang="scss" scoped>
.image-item {
  margin-top: 20px;
  margin-bottom: 20px;
}
form .form-wrap {
  align-items: start !important;
}
.selection-txt {
  margin-left: 10px;
}
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss">
</style>

<script>
import {defineComponent} from '@vue/composition-api';
export default defineComponent({
  props : {
    viewOnly : {
      type    : Boolean,
      default : false
    },
    commonData : {
      type    : Object,
      default : () => {}
    },
    documentFiles : {
      type    : Array,
      default : () => []
    },
    errors : {
      type    : Object,
      default : () => {}
    },
    addDocumentFile : {
      type    : Function,
      default : Object
    }
  },
  data() {
    return {
    }
  },
  computed : {
    computeErrors() {
      return this.errors || {}
    }
  },
  methods : {
  },
  /*
   * Watch : {
   *   errors(value) {
   *     const keys = Object.keys(value)
   *     if (keys.length > 0) {
   *       const refkey = keys[0]
   *       if (value[keys[0]] && this.$refs[refkey]) {
   *         this.$refs[refkey].focus()
   *       }
   *     }
   *   }
   * }
   */
})
</script>
<template>
<div>
  <div class="form-wrap align-start">
    <p class="form-wrap-tit">
      {{ $t('INSPECTION_DOCUMENT_FILES_LABEL') }}
      <em v-if="!viewOnly" class="req">{{ $t('MEMBER_REQUEST_FORM_REQUIRED_LABEL') }}</em>
    </p>
    <div v-if="viewOnly" class="form-wrap-input">
      <div v-if="documentFiles && documentFiles.length > 0">
        <div v-for="(doc, index) in documentFiles" :key="index" class="form-wrap align-center">
          <p class="form-wrap-tit document">{{ doc.label }}</p>
          <div  class="ipt-file ipt-file-box">
            <p v-if="doc.file || doc.key" class="ipt-file-txt">{{ doc.fileName }}</p>
            <p v-else class="ipt-file-txt">{{  'なし' }}</p>
          </div>
        </div>
      </div>
      <div v-else>なし</div>
    </div>
    <div v-else class="form-wrap-input">
      <v-radio-group v-model="commonData.document_file" :error="!!computeErrors.document_file" @change="() => computeErrors.document_file=null">
        <v-radio class="mb-0" label="あり" :value="1"></v-radio>
        <div
          v-bind:class="{'err': computeErrors.documentFilePath || computeErrors.documentFilePathAtleast1}"
          name="documentFilePathAtleast1"
        >
          <div v-for="(doc, index) in documentFiles" :key="index" class="form-wrap align-center mt-4">
            <p class="form-wrap-tit document">{{ doc.label }}</p>
            <div class="ipt-file ipt-file-box" v-if="doc.key || doc.file">
              <label @click="doc.file = null; doc.key=null;">{{$t('MEMBER_REQUEST_FORM_FILE_DELETE_BUTTON_LABEL')}}</label>
              <p class="ipt-file-txt">{{doc.fileName}}</p>
            </div>
            <div class="ipt-file ipt-file-box"  v-if="!(doc.file || doc.key)">
              <label class="txt-color"
                v-bind:class="{'err': computeErrors[`documentFilePath${doc.id}`]}"
                @click="computeErrors[`documentFilePath${doc.id}`]=null"
                :ref="`documentFilePath${doc.id}`"
              >
                <input type="file"
                  @change="(event) => addDocumentFile(event, doc.id, $t('MY_PAGE_UPDATE_FILE_SIZE_LIMIT_ERROR_MESSAGE'))">{{$t('INSPECTION_DOCUMENT_FILE_SELECT')}}
              </label>
              <p class="ipt-file-txt" v-html="$t('INSPECTION_STEP1_FILE_NOT_SELECTED')"></p>
              <!-- <p class="err-txt">{{computeErrors.documentFilePath || computeErrors.documentFilePathAtleast1}}</p> -->
            </div>
          </div>
        </div>
        <v-radio class="mt-4" label="なし" :value="0">
          <template v-slot:label>
            <div>なし <strong class="form-wrap-tit">(書類無し：紛失)</strong></div>
          </template>
        </v-radio>
        <label v-if="computeErrors.document_file || computeErrors.documentFilePath || computeErrors.documentFilePathAtleast1" class="inps-err-txt">{{ computeErrors.document_file || computeErrors.documentFilePath || computeErrors.documentFilePathAtleast1 }}</label>
      </v-radio-group>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
  .form-wrap-tit.document {
    text-align: right !important;
  }
  .form-wrap-input .err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  .txt-color {
    color: rgba(0, 0, 0, 0.6);
  }
  .inps-err-txt {
    position: relative;
    margin-left: 10px;
    color: #f00;
    font-size: 18px;
    font-weight: normal;
  }
  .ipt-file-txt {
    overflow-wrap: anywhere;
  }

  @media only screen and (max-width: 767px) {

    .form-wrap-tit.document {
      text-align: left !important;
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>

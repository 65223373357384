import Base from '@/common/base'
import router from '@/router'
import _ from 'lodash'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useLoading from './useLoading'
const loadingStore = useLoading()

const initCommonData = {
  estimatePrice             : null,
  name                      : null,
  tel                       : null,
  address                   : null,
  authLetterFilePath        : [],
  identityFilePath          : [],
  documentFilePath          : [],
  identity_file             : null,
  document_file             : null,
  maker                     : null,
  bike_type                 : null,
  bike_type_other           : null,
  first_inspection          : null,
  first_inspection_other    : null,
  inspec_expire             : null,
  inspec_expire_other       : null,
  fuel_type                 : null,
  meter_speed               : null,
  meter_speed_other         : null,
  meter_taco                : null,
  meter_taco_other          : null,
  horn                      : null,
  horn_other                : null,
  headlight_hi              : null,
  headlight_hi_other        : null,
  headlight_lo              : null,
  headlight_lo_other        : null,
  winker_fl                 : null,
  winker_fl_other           : null,
  winker_fr                 : null,
  winker_fr_other           : null,
  winker_rl                 : null,
  winker_rl_other           : null,
  winker_rr                 : null,
  winker_rr_other           : null,
  tail_light_small          : null,
  tail_light_small_other    : null,
  tail_light_break          : null,
  tail_light_break_other    : null,
  battery_health            : null,
  battery_health_other      : null,
  radiator_leak             : null,
  radiator_leak_other       : null,
  engine_white_smoke        : null,
  engine_white_smoke_other  : null,
  engine_noise              : null,
  engine_noise_other        : null,
  front_fork_strain         : null,
  front_fork_strain_other   : null,
  front_fork_rusty          : null,
  front_fork_rusty_other    : null,
  front_fork_oil_leak       : null,
  front_fork_oil_leak_other : null,
  handle                    : null,
  handle_other              : null,
  front_break               : null,
  front_break_other         : null,
  rear_break                : null,
  rear_break_other          : null,
  engine_condition          : null,
  engine_condition_other    : null,
  tire_condition            : null,
  tire_condition_other      : null,
  modification              : null,
  modification_other        : null,
  scratch                   : null,
  scratch_other             : null,
  other_memo                : null,
  other_memo_other          : null,
  model                     : null,
  model_other               : null,
  frame_no                  : null,
  frame_no_other            : null,
  engine_type               : null,
  engine_type_other         : null,
  mileage                   : null,
  mileage_other             : null,
  color                     : null,
  color_other               : null,
  cubic_capacity            : null,
  cubic_capacity_other      : null,
  engine_serial             : null,
  engine_serial_other       : null,
}

const initItemImages = [
  {id : 1, title : '左', fileName : null, key : null, preview : null, label : '左'},
  {id : 2, title : '右', fileName : null, key : null, preview : null, label : '右'},
  {id : 3, title : '前', fileName : null, key : null, preview : null, label : '前'},
  {id : 4, title : '後', fileName : null, key : null, preview : null, label : '後'},
  {id : 5, title : 'メーター', fileName : null, key : null, preview : null, label : 'メーター'},
  {id : 6, title : 'タンク中', fileName : null, key : null, preview : null, label : 'タンク中'},
  {id : 7, title : 'エンジン左', fileName : null, key : null, preview : null, label : 'エンジン左'},
  {id : 8, title : 'エンジン右', fileName : null, key : null, preview : null, label : 'エンジン右'},
  {id : 9, title : 'エンジン前', fileName : null, key : null, preview : null, label : 'エンジン前'},
  {id : 10, title : 'エンジン番号', fileName : null, key : null, preview : null, label : 'エンジン番号'},
  {id : 11, title : 'シート', fileName : null, key : null, preview : null, label : 'シート'},
  {id : 12, title : '前方ホイール左', fileName : null, key : null, preview : null, label : '前方ホイール左'},
  {id : 13, title : '前方ホイール右', fileName : null, key : null, preview : null, label : '前方ホイール右'},
  {id : 14, title : '前方タイヤ', fileName : null, key : null, preview : null, label : '前方タイヤ'},
  {id : 16, title : '後方ホイール左', fileName : null, key : null, preview : null, label : '後方ホイール左'},
  {id : 17, title : '後方ホイール右', fileName : null, key : null, preview : null, label : '後方ホイール右'},
  {id : 15, title : '後方タイヤ', fileName : null, key : null, preview : null, label : '後方タイヤ'},
  {id : 19, title : 'リアサスペンション左', fileName : null, key : null, preview : null, label : 'リアサスペンション左'},
  {id : 18, title : 'リアサスペンション右', fileName : null, key : null, preview : null, label : 'リアサスペンション右'},
]

const initOptionalImages = [
  {id : 1, title : '', fileName : null, key : null, preview : null},
  {id : 2, title : '', fileName : null, key : null, preview : null},
  {id : 3, title : '', fileName : null, key : null, preview : null},
  {id : 4, title : '', fileName : null, key : null, preview : null},
  {id : 5, title : '', fileName : null, key : null, preview : null},
  {id : 6, title : '', fileName : null, key : null, preview : null},
  {id : 7, title : '', fileName : null, key : null, preview : null},
  {id : 8, title : '', fileName : null, key : null, preview : null},
  {id : 9, title : '', fileName : null, key : null, preview : null},
  {id : 10, title : '', fileName : null, key : null, preview : null}
]

const initDocumentFiles = [
  {id : 1, label : '車検証', key : null, file : null, fileName : null},
  {id : 2, label : '予備検査証', key : null, file : null, fileName : null},
  {id : 3, label : '廃車・返納・一時抹消証明書', key : null, file : null, fileName : null},
  {id : 4, label : '通関証明証', key : null, file : null, fileName : null},
]

const useInspectionPreview = defineStore('useInspectionPreview', {
  state : () => {
    return {
      // Locale: 'ja',
      memberId            : null,
      manageNo            : null,
      requestTitle        : null,
      comment             : null,
      commonData          : _.cloneDeep(initCommonData),
      itemImages          : _.cloneDeep(initItemImages),
      optionalImages      : _.cloneDeep(initOptionalImages),
      documentFiles       : _.cloneDeep(initDocumentFiles),
      authLetterFiles     : [],
      identityFiles       : [],
      bodyTypes           : {},
      requestData         : {},

      // Constants
      constants                : {},
    }
  },
  getters : {
  },
  actions : {
    resetData() {
      console.log('resetData')
      this.memberId = null
      this.manageNo = null
      this.requestTitle = null
      this.comment = null
      this.constants = {}
      this.commonData = _.cloneDeep(initCommonData)
      this.bodyTypes = JSON.parse(`{${(this.constants.SELECT_BODY_TYPE || []).map(x => `"${x.value}":false`).join(',')}}`)
      this.itemImages = _.cloneDeep(initItemImages)
      this.optionalImages = _.cloneDeep(initOptionalImages)
      this.documentFiles = _.cloneDeep(initDocumentFiles)
      this.authLetterFiles = []
      this.identityFiles = []
      this.requestData = {}
    },
    memberCheck(itemRequestNo, locale = 'ja') {
      return Methods.apiExcute('private/check-member-for-request-item', locale, {
        item_request_no : itemRequestNo
      }).then(data => {
        if (data) {
          if (data[0].member_flag === true) {
            return Promise.resolve()
          }
        }
        return Promise.reject(new Error('Invalid page request'))
      })
    },
    getConstants(itemRequestNo, locale = 'ja') {
      console.log('getConstants')
      loadingStore.setLoading(true)
      Methods.apiExcute('private/get-request-item-preview-constants', locale, {
        item_request_no : itemRequestNo
      }).then(data => {
        // LoadingStore.setLoading(false)
        this.constants = {}
        data.constants.forEach(row => {
          switch (row.key_string) {
          case 'SELECT_MAKER':
          case 'DOCUMENT_FILES':
          case 'SELECT_YES_NO':
          case 'SELECT_FUEL_TYPE':
          case 'SELECT_BATTERY_HEALTH':
          case 'SELECT_RADIATOR_LEAK':
          case 'SELECT_ARI_NASI':
          case 'SELECT_TEXT_AREA':
          case 'SELECT_BODY_TYPE':
          case 'SELECT_INPUT_OPTION':
          case 'SELECT_IDENTITY_FILE':
          case 'SELECT_YES_NO_TACO':
            if (!this.constants[row.key_string]) {
              this.constants[row.key_string] = []
            }
            this.constants[row.key_string].push({
              value       : Number(row.value1),
              label       : row.value2,
              isTextInput : String(row.value3) === '1'
            })
            break
          default:
            break
          }
        })

        this.memberId = data.memberId

        // Saved request data
        if (this.memberId) {
          const itemRequestDetail = data.requestDetail
          const itemRequestFiles = data.requestFiles

          // Common data
          if (itemRequestDetail) {
            this.commonData = _.cloneDeep(Object.assign(
              {},
              initCommonData,
              itemRequestDetail.item_free_field ?? {},
              {
                name    : itemRequestDetail.member_free_field ? itemRequestDetail.member_free_field.name : null,
                tel     : itemRequestDetail.member_free_field ? itemRequestDetail.member_free_field.tel : null,
                address : itemRequestDetail.member_free_field ? itemRequestDetail.member_free_field.address : null,
              },
            ))
            this.requestTitle = _.clone(itemRequestDetail.title)
            this.comment = _.clone(itemRequestDetail.comment)
            this.manageNo = _.clone(itemRequestDetail.manage_no)

            // Saved document files
            if (this.commonData && this.commonData.documentFilePath) {
              this.documentFiles.map(doc => {
                const savedDoc = this.commonData.documentFilePath.find(x => String(x.id) === String(doc.id))
                if (savedDoc) {
                  doc.key = savedDoc.key
                  doc.fileName = Base.getFileName(savedDoc.key)
                }
              })
            }
            // Saved identity file
            if (this.commonData && this.commonData.identityFilePath && this.commonData.identityFilePath.length > 0) {
              const identityS3 = this.commonData.identityFilePath[0]
              this.identityFiles.push({key : identityS3, name : Base.getFileName(identityS3), file : null})
            }
          }

          // Member info
          if (!this.commonData.name) this.commonData.name = _.clone(data.member.name)
          if (!this.commonData.tel) this.commonData.tel = _.clone(data.member.tel)
          if (!this.commonData.address) this.commonData.address = _.clone(data.member.address)

          // Body types
          if (this.commonData.body_types) {
            const tmpBdTyp = (this.constants.SELECT_BODY_TYPE || []).map(x => {
              const typ = this.commonData.body_types.find(y => String(y) === String(x.value))
              if (typ) return `"${x.value}":true`
              return `"${x.value}":false`
            })
            this.bodyTypes = JSON.parse(`{${tmpBdTyp.join(',')}}`)
          }

          // Saved item images
          if (itemRequestFiles) {
            this.itemImages.map(img => {
              const savedImg = itemRequestFiles
                .find(x => String(x.division) === '1' && String(x.physical_name) === String(img.id))
              if (savedImg) {
                img.key = savedImg.file_path
                img.title = savedImg.file_title
                img.fileName = savedImg.file_path
                img.preview = null
              }
            })
            // Saved optional images
            this.optionalImages.map(img => {
              const savedImg = itemRequestFiles
                .find(x => String(x.division) === '2' && String(x.physical_name) === String(img.id))
              if (savedImg) {
                img.key = savedImg.file_path
                img.title = savedImg.file_title
                img.fileName = savedImg.file_path
                img.preview = null
              }
            })
          }
        }

        loadingStore.setLoading(false)
      })
        .catch(error => {
          loadingStore.setLoading(false)
          Methods.parseHtmlResponseError(router, error)
        })
    },
    getPreviewData(type, index, locale = 'ja') {
      console.log('getPreviewData: ', type, index, locale)
      const image = type === 1 ? this.itemImages[index] : this.optionalImages[index]
      if (!image || !image.key || image.preview) {
        return
      }
      Methods.apiExcute('private/download-file', locale, {key : image.key})
        .then(url => {
          console.log('url: ', url)
          image.preview = url
        })
    },
    getRequestData(itemRequestNo, locale = 'ja') {
      console.log('getRequestData')
      loadingStore.setLoading(true)
      Methods.apiExcute('private/get-request-data', locale, {
        item_request_no : itemRequestNo
      }).then(data => {
        loadingStore.setLoading(false)
        this.requestData = data[0] || {}
      })
    }
  },
})

export default useInspectionPreview

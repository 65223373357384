import { render, staticRenderFns } from "./PopupAuction.vue?vue&type=template&id=43020648&scoped=true&"
import script from "./PopupAuction.vue?vue&type=script&lang=js&"
export * from "./PopupAuction.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/style.scss?vue&type=style&index=0&id=43020648&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43020648",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCardText,VDialog})

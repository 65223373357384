<template>
<v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
  <v-card style="border-radius: 1rem;">
    <v-card-text class="border-bottom mt-5 popup popup-fileup" style="display: block;">
      <div class="popup-wrap small">
        <a class="popup-close js-close">
          <img @click.stop="closeDialog" src="@/assets/img/common/ic_close.svg" alt="">
        </a>
        <div v-if="errors && Object.keys(errors).length > 0" class="popup-content">
          <div class="popup-content-form">
            <p class="text-center text-red" v-for="err in errors" :key="err">{{ err }}<br></p>
          </div>
          <div class="popup-content-form-btnWrap">
            <v-btn @click.stop="closeDialog" color="red" class="white--text">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL")}}</v-btn>
          </div>
        </div>
        <div v-else class="popup-content">
          <div class="popup-content-form">
            <p class="text-center" v-if="requestCategoryId === '3'">{{ $t('INSPECTION_REQUEST_CONFIRM_EXPLANATION_AGENCY_MESSAGE').replace('#', $options.filters.number2string(exhibitFee, '', '円')) }}<br></p>
            <p class="text-center" v-else-if="requestCategoryId === '4'">{{ $t('INSPECTION_REQUEST_CONFIRM_EXPLANATION_AUCTION_MESSAGE').replace('#', $options.filters.number2string(exhibitFee, '', '円')) }}<br></p>
            <p class="text-center" v-else-if="requestCategoryId === '2'">{{ $t('INSPECTION_REQUEST_CONFIRM_EXPLANATION_PURCHASE_MESSAGE') }}<br></p>
            <p class="text-center">{{ $t('INSPECTION_REQUEST_CONFIRM_EXPLANATION_MESSAGE') }}</p>
          </div>
          <div class="popup-content-form-btnWrap">
            <v-btn @click.stop="closeDialog" color="red" class="white--text">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL")}}</v-btn>
            <v-btn @click="request" color="#364A81" class="white--text">{{$t("INSPECTION_REQUEST_COMFIRM_DIALOG_OK_LABEL")}}</v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import CommonFilters from '@/common/commonFilters'
import inspectionAuctionRequest from '@/stores/inspectionAuctionRequest'
import useLoading from '@/stores/useLoading'
import useRequestResultPreview from '@/stores/useRequestResultPreview'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const auctionRequestStore = inspectionAuctionRequest()
    const requestResultStore = useRequestResultPreview()
    return {
      loadingStore,
      auctionRequestStore,
      requestResultStore
    }
  },
  components : {},
  mixins     : [CommonFilters],
  props      : {
    type : {
      type : Number
    },
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
    closeDialog : {
      type    : Function,
      default : Object
    },
    requestCategoryId : {
      type    : String,
      default : null
    }
  },
  data() {
    return {
    }
  },
  methods : {
    request() {
      this.auctionRequestStore.requestItem()
    }
  },
  computed : {
    exhibitFee() {
      const assessmentAmount = this.requestResultStore.requestResultData.assessment_amount
      const fee = this.auctionRequestStore.getExhibitBaseFee(assessmentAmount, this.requestCategoryId)
      return fee ? fee.value1 : 0
    },
    errors() {
      return this.auctionRequestStore.errors
    }
  }
})
</script>
<style lang="scss" scoped>
  .popup-content-form-btnWrap button {
    margin: 0 20px;
  }
  .text-red {
    color: red;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>

<template>
  <section id="exhibit" class="exhibit">
    <div class="wrap1160 exhibit-wrap">
      <h1>{{ $t('INSPECTION_AUCTION_REQUEST_FROM_TITLE') }}</h1>
      <form class="entry-result" id="entry-form">
        <div class="entry-formInner bgLGray">
          <div v-for="(text, i) in errors" :key="i" class="text-red mb-3">{{ text }}</div>
          <div class="textarea-wrap mt0_sp">
            <p class="form-wrap-tit pr0">{{ $t('INSPECTION_AUCTION_REQUEST_POST_CODE_LABEL') }}<em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em></p>
            <div class="form-wrap-input">
              <input
               type="text" class="mb-1"
               style="background-color: #fff;"
               v-model="postCode"
               @keydown="errors.postCode=null"
               v-bind:class="{'err': errors.postCode}"
               />
              <input
               class="ipt-btn-gray ml-5"
               type="button"
               :value="$t('MEMBER_REQUEST_FORM_SEARCH_POST_CODE_BUTTON_LABEL')"
               @click="errors.postCode=null,auctionRequestStore.searchPostCode()"
              >
            </div>
          </div>
          <div class="textarea-wrap mt0_sp">
            <p class="form-wrap-tit pr0">{{ $t('INSPECTION_AUCTION_REQUEST_ADDRESS_LABEL') }}<em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em></p>
            <div class="form-wrap-input">
              <input
               type="text"
               class="full mb-1"
               v-model="address"
               v-bind:class="{'err': errors.address}"
               @keydown="errors.address=null"
               />
            </div>
          </div>
          <div class="textarea-wrap mt0_sp">
            <p class="form-wrap-tit pr0">{{ $t('INSPECTION_AUCTION_REQUEST_COMMENT_LABEL') }}</p>
            <div class="form-wrap-input">
              <textarea class="full" cols="30" rows="3" v-model="comment"></textarea>
            </div>
          </div>

          <RuleCheckBox
            ref="ruleCheckBox"
            @change="(val) => {ruleCheckBtnClicked = val}"
          >
          <p class="text-center form-privacy mt-3">
            出品いただく前に「
            <a
              class="link-text"
              @click="$routerGoNewWindow(
                $define.PAGE_NAMES.OTHER_GUIDE_TERMS_PAGE_NAME,
                null,
                {lang: $i18n.locale}
                )"
            >利用規約</a>
            」、「
            <a
              class="link-text"
              @click="$routerGoNewWindow(
                $define.PAGE_NAMES.OTHER_GUIDE_AUCTION_TERMS_PAGE_NAME,
                null,
                {lang: $i18n.locale}
                )"
            >オークション利用規約</a>
            」、「
            <a
            class="link-text"
            @click="$routerGoNewWindow(
              $define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME,
              null,
              {lang: $i18n.locale}
              )"
            >各種手数料</a>
            」をご確認ください。
          </p>
          </RuleCheckBox>

          <div class="form-wrap flexStart">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_REQUEST_CATEGORY_LABEL') }}</p>
            <div class="form-wrap-choice">
              <div class="form-wrap-choice-btnWrap">
                <button
                  class="form-wrap-choice-btn normal js-popup"
                  v-bind:class="{'grey': !ruleCheckBtnClicked, 'orange': ruleCheckBtnClicked}"
                  name="auction"
                  :disabled="!ruleCheckBtnClicked"
                  @click="(event) => {event.preventDefault();requestCategoryId = '3'; agency()}"
                >
                  {{ $t('INSPECTION_AUCTION_REQUEST_CATEGORY_AGENCY_BUTTON_LABEL') }}
                  <span>{{ $t('INSPECTION_AUCTION_REQUEST_CATEGORY_AGENCY_BUTTON_2_LABEL') }}</span>
                </button>
                <button
                  class="form-wrap-choice-btn normal js-popup"
                  v-bind:class="{'grey': !ruleCheckBtnClicked}"
                  name="auction"
                  @click="(event) => {event.preventDefault();requestCategoryId = '4'; auction()}"
                  :disabled="!ruleCheckBtnClicked"
                >
                  {{ $t('INSPECTION_AUCTION_REQUEST_CATEGORY_MYSELF_BUTTON_LABEL') }}
                  <span>{{ $t('INSPECTION_AUCTION_REQUEST_CATEGORY_MYSELF_BUTTON_2_LABEL') }}</span>
                </button>
              </div>
              <a
               class="form-wrap-choice-textlink"
               @click="$routerGoNewWindow($define.PAGE_NAMES.GUIDE_AUCTION_AGENCY_PAGE_NAME)"
               >{{ $t('INSPECTION_AUCTION_REQUEST_LINK_LABEL') }}</a>
            </div>
          </div>
        </div>

        <h2>{{requestResultData.request_category_name}}依頼結果</h2>
        <RequestResultDetailPreview
          :isPreview="true"
          :requestResultData="requestResultData"
          :judgeStatusPreview="false"
        />

        <h2>{{ $t('INSPECTION_AUCTION_DETAIL_FROM_TITLE') }}</h2>
        <RequestDetailPreview
          :isPreview="true"
          :manageNo="manageNo"
          :requestTitle="requestTitle"
          :comment="commentPreview"
          :commonData="commonData"
          :identityFiles="identityFiles"
          :bodyTypes="bodyTypes"
          :constants="constants"
          :getPreviewData="inspectionPreviewStore.getPreviewData"
          :imageItems="inspectionPreviewStore.itemImages"
          :imageOptionals="inspectionPreviewStore.optionalImages"
          :documentFiles="inspectionPreviewStore.documentFiles"
          :errors="{}"
        />

        <div class="btn-form text-center mt-3">
          <input
            type="button"
            class="btn-back"
            :value="$t('MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL')"
            @click="$routerGo($define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME)"
          >
          <input
            type="button"
            class="blue"
            :value="$t('INSPECTION_AUCTION_REQUEST_TOP_BTN_LABEL')"
            @click="gotoTop"
          >
        </div>
      </form>
    </div>

    <!-- 申請内容確認モーダル start -->
    <PopupAuction
      :isOpenDialog="popupAuctionDialog"
      :detailData="detailData"
      :closeDialog="closePopupAuction"/>
    <!-- 申請内容確認モーダル end -->
  </section>
</template>

<script>
import inspectionAuctionRequest from '@/stores/inspectionAuctionRequest'
import useInspectionPreview from '@/stores/useInspectionPreview'
import useLoading from '@/stores/useLoading'
import useRequestResultPreview from '@/stores/useRequestResultPreview'
import {defineComponent} from '@vue/composition-api'
import RuleCheckBox from '../Checkbox/RuleCheckBox'
import PopupAuction from './parts/PopupAuction'
import RequestDetailPreview from './parts/RequestDetailPreview'
import RequestResultDetailPreview from './parts/RequestResultDetailPreview'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const inspectionPreviewStore = useInspectionPreview()
    const auctionRequestStore = inspectionAuctionRequest()
    const resultPreviewStore = useRequestResultPreview()
    return {
      loadingStore,
      inspectionPreviewStore,
      auctionRequestStore,
      resultPreviewStore
    }
  },
  components : {
    PopupAuction,
    RequestDetailPreview,
    RequestResultDetailPreview,
    RuleCheckBox
  },
  props : {},
  data() {
    return {
      ruleCheckBtnClicked : false
    }
  },
  computed : {
    detailData() {
      return this.auctionRequestStore.detailData
    },
    postCode : {
      get() {
        return this.auctionRequestStore.postCode
      },
      set(val) {
        this.auctionRequestStore.postCode = val
      }
    },
    address : {
      get() {
        return this.auctionRequestStore.address
      },
      set(val) {
        this.auctionRequestStore.address = val
      }
    },
    comment : {
      get() {
        return this.auctionRequestStore.comment
      },
      set(val) {
        this.auctionRequestStore.comment = val
      }
    },
    requestCategoryId : {
      get() {
        return this.auctionRequestStore.requestCategoryId
      },
      set(val) {
        this.auctionRequestStore.requestCategoryId = val
      }
    },
    manageNo() {
      return this.inspectionPreviewStore.manageNo
    },
    requestTitle() {
      return this.inspectionPreviewStore.requestTitle
    },
    commentPreview() {
      return this.inspectionPreviewStore.comment
    },
    commonData() {
      return this.inspectionPreviewStore.commonData
    },
    constants() {
      return this.inspectionPreviewStore.constants
    },
    identityFiles() {
      return this.inspectionPreviewStore.identityFiles
    },
    bodyTypes() {
      return this.inspectionPreviewStore.bodyTypes
    },
    requestResultData() {
      return this.resultPreviewStore.requestResultData
    },
    errors() {
      return this.auctionRequestStore.errors
    },
    popupAuctionDialog() {
      return this.auctionRequestStore.popupAuctionDialog
    }
  },
  methods : {
    isNumber(evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      }
      return true
    },
    auction() {
      this.auctionRequestStore.auction()
    },
    closePopupAuction() {
      this.auctionRequestStore.popupAuctionDialog = false
    },
    agency() {
      this.auctionRequestStore.agency()
    },
    gotoTop() {
      window.scrollTo({
        top      : 0,
        left     : 0,
        behavior : 'smooth'
      })
    }
  },
  watch : {
  }
})
</script>
<style scoped lang="scss">

@media only screen and (min-width: 768px) {
    p.err-txt {
      // position: absolute;
      // top: 50%;
      // left: 450px;
      // -webkit-transform: translateY(-50%);
      // transform: translateY(-50%);
      // width: 100%;
      // max-width: 290px;
    }
  }
  @media only screen and (max-width: 767px) {
    p.err-txt {
        position: static;
        -webkit-transform: none;
        transform: none;
        max-width: 100%;
        margin-top: 5px;
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>

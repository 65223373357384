<template>
<v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
  <v-card style="border-radius: 1rem;">
    <v-card-text class="border-bottom mt-5 popup popup-fileup" style="display: block;">
      <div class="popup-wrap small">
        <a class="popup-close js-close">
          <img @click.stop="closeDialog" src="@/assets/img/common/ic_close.svg" alt="">
        </a>
        <div class="popup-content">
          <div class="popup-content-form">
            <div v-if="imgPreview" class="popup-content-form-filebox js-fileDrop">

              <v-img
                :src="imgPreview"
                :aspect-ratio="4/3"
                @click.stop="chooseFiles"
                cover
                class="grey lighten-2"
              >
                <input
                  type="file"
                  :name="`file${imageIndex}`"
                  id="file-input1"
                  accept=".jpg,.png"
                  @change="selectFileChange"
                >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <div v-else class="popup-content-form-filebox js-fileDrop">
              <p class="text-center">
                {{ $t('INSPECTION_IMAGE_UPLOAD_DRAG_1') }}<br>
                {{ $t('INSPECTION_IMAGE_UPLOAD_DRAG_2') }}
              </p>
              <label>
                  <input
                    type="file"
                    name="file"
                    id="file-input"
                    accept=".jpg,.png"
                    @change="selectFileChange"
                  >{{ $t('INSPECTION_IMAGE_UPLOAD_FILE_SEL') }}
              </label>
            </div>
            <div v-if="type === 2" class="popup-content-form-wrap">
                <p>{{ $t('INSPECTION_IMAGE_UPLOAD_TITLE') }}</p>
                <input
                  type="text"
                  name="text"
                  v-model="tempImage.title"
                  v-bind:class="{'err': errors.title}"
                  @keydown="() => errors.title=null"
                  ref="title"
                  >
                <p class="err-txt" v-if="errors.title">{{ errors.title }}</p>
            </div>
          </div>
          <div class="popup-content-form-btnWrap d-flex">
            <button @click="openImageClearModal" class="popup-content-form-btn clear" type="submit" :disabled="!imgPreview">
              {{ $t('MEMBER_EDIT_CONFIRM_CLEAR_BUTTON_LABEL') }}
            </button>
            <button @click="updateImage" class="popup-content-form-btn update" type="submit">
              {{ $t('MEMBER_EDIT_CONFIRM_SEND_BUTTON_LABEL') }}
            </button>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>

  <PopupSlot
    :is-open-dialog="imageClearModal"
    @close="imageClearModal=false"
    @action="clearImage()"
  >
    <p class="text-center">{{ $t('INSPECTION_STEP2_IMAGE_CLEAR_DIALOG_TXT') }}</p>
  </PopupSlot>
</v-dialog>
</template>

<script>
import useInspection from '@/stores/useInspection'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import PopupSlot from './PopupSlot'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const inspectionStore = useInspection()
    return {
      loadingStore,
      inspectionStore
    }
  },
  components : {PopupSlot},
  props      : {
    imageIndex : {
      type : Number,
    },
    type : {
      type : Number
    },
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
    closeDialog : {
      type    : Function,
      default : Object
    },
  },
  data() {
    return {
      tempImage         : {},
      errors            : {},
      imageClearModal : false
    }
  },
  computed : {
    image() {
      return this.type === 1
        ? this.inspectionStore.itemImages[this.imageIndex]
        : this.inspectionStore.optionalImages[this.imageIndex]
    },
    imgPreview() {
      // Console.log('imgPreview', this.tempImage.preview)
      return this.tempImage.preview || null
    }
  },
  watch : {
    'imageIndex'() {
      this.reloadData()
    }
  },
  created() {
    this.reloadData()
  },
  destroyed() {
    console.log('destroyed')
  },
  methods : {
    chooseFiles() {
      document.getElementById('file-input1').click()
    },
    reloadData() {
      this.errors = {}
      this.tempImage = this.type === 1
        ? Object.assign({}, this.inspectionStore.itemImages[this.imageIndex])
        : Object.assign({}, this.inspectionStore.optionalImages[this.imageIndex])
    },
    clearImage() {
      console.log('clearImage: ', this.imageIndex, this.tempImage)
      this.loadingStore.setLoading(true)
      if (this.tempImage.file || this.tempImage.key) {
        this.inspectionStore.clearImage(this.imageIndex, this.type, this.tempImage)
          .then(() => {
            this.tempImage.key = null
            this.tempImage.file = null
            this.tempImage.fileName = null
            this.tempImage.preview = null
            if (this.type === 2) {
              this.tempImage.title = null
            }
            this.loadingStore.setLoading(false)
            this.imageClearModal = false
            this.closeDialog()
          })
          .catch(err => {
            console.log('err: ', err)
            this.imageClearModal = false
            this.loadingStore.setLoading(false)
          })
      }
    },
    updateImage() {
      console.log('imageIndex: ', this.imageIndex)
      console.log('editImage: ', this.tempImage)

      if (this.tempImage.file || this.tempImage.key) {
        /*
         * Validate
         * 任意画像の場合はタイトルが必須
         */
        if (this.type === 2 && !this.tempImage.title) {
          this.errors = {title : '入力してください。'}
          return
        }
        // Upload to s3
        this.loadingStore.setLoading(true)
        this.inspectionStore.uploadItemImage(this.imageIndex, this.type, this.tempImage)
          .then(key => {
            console.log('updateImage: ', key)
            this.tempImage.key = key
            this.loadingStore.setLoading(false)
            this.closeDialog()
          })
          .catch(err => {
            console.log('err: ', err)
            this.loadingStore.setLoading(false)
          })
      } else {
        this.closeDialog()
      }
    },
    selectFileChange(file) {
      console.log('selectFileChange')
      if (file.target.files.length > 0) {
        // Check file extension
        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.target.files[0].type)) {
          this.errors = Object.assign({}, this.errors, {itemImage : this.$t('MY_PAGE_UPDATE_FILE_EXT_LIMIT_ERROR_MESSAGE')})
          return
        }
        const err = this.inspectionStore.checkFileSize(
          file.target.files[0],
          this.$t('MY_PAGE_UPDATE_FILE_SIZE_LIMIT_ERROR_MESSAGE')
        )
        if (err) {
          this.errors = Object.assign({}, this.errors, {itemImage : err})
        } else {
          this.tempImage.file = file.target.files[0]
          this.tempImage.fileName = file.target.files[0].name
          this.tempImage.key = null

          // Get preview image
          this.createImagePreview(file.target.files[0])
            .then(dataURI => {
              this.tempImage.preview = dataURI
            })
            .catch(err2 => {
              console.log('err2: ', err2)
            })
        }
      }
    },
    createImagePreview(file) {
      console.log('createImage')
      if (this.disabled) return Promise.resolve(null)
      const formData = new FormData()
      formData.append('file', file)

      // 動画表示用
      return Promise.resolve()
        .then(() => {
          if (file.name.indexOf('.mp4') !== -1) {
            return this.getVideoCover(file)
          }
          return Promise.resolve(file)
        })
        .then(fileCover => {
          console.log('fileCover: OK')
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = e => {
              const dataURI = e.target.result
              return resolve(dataURI)
            }
            reader.readAsDataURL(fileCover)
          })
        })
    },
    getVideoCover(file, seekTo = 0.0) {
      console.log('getting video cover for file: ', file)
      return new Promise((resolve, reject) => {
        // Load the file to a video player
        const videoPlayer = document.createElement('video')
        videoPlayer.setAttribute('src', URL.createObjectURL(file))
        videoPlayer.load()
        videoPlayer.addEventListener('error', ex => {
          reject(new Error('error when loading video file', ex))
        })
        // Load metadata of the video to get video duration and dimensions
        videoPlayer.addEventListener('loadedmetadata', () => {
          // Seek to user defined timestamp (in seconds) if possible
          if (videoPlayer.duration < seekTo) {
            reject(new Error('video is too short.'))
            return
          }
          // Delay seeking or else 'seeked' event won't fire on Safari
          setTimeout(() => {
            videoPlayer.currentTime = seekTo
          }, 200)
          // Extract video thumbnail once seeking is complete
          videoPlayer.addEventListener('seeked', () => {
            console.log('video is now paused at %ss.', seekTo)
            // Define a canvas to have the same dimension as the video
            const canvas = document.createElement('canvas')
            canvas.width = videoPlayer.videoWidth
            canvas.height = videoPlayer.videoHeight
            // Draw the video frame to canvas
            const ctx = canvas.getContext('2d')
            ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
            // Return the canvas image as a blob
            ctx.canvas.toBlob(
              blob => {
                resolve(blob)
              },
              'image/jpeg',
              0.75 /* Quality */
            )
          })
        })
      })
    },
    openImageClearModal() {
      this.imageClearModal = true
    }
  }
})
</script>
<style lang="scss" scoped>
  .popup-content-form-wrap input {
    background: #fff !important;
  }
  .popup-content-form-wrap p.err-txt {
    font-size: 12px;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>

<template>
  <div>
    <div class="wrap1160 exhibit-wrap">
      <h1>{{ $t('MEMBER_REQUEST_CONFIRM_TITLE') }}</h1>
      <form id="entry-form">
        <div v-if="errors.length > 0">
          <p class="err-txt">{{ errors }}</p>
        </div>
        <div class="entry-formInner bgLGray">
          <p class="entry-form-info" v-html="$t('MEMBER_REQUEST_CONFIRM_EXPLANATION_MESSAGE')"></p><br /><br />
          <div class="form-wrap">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_EXHIBITION_DIVISION') }}</p>
            <div class="form-wrap-input">
              <div v-if="requestCategoryId === '3'">{{ $t('INSPECTION_AUCTION_REQUEST_CATEGORY_AGENCY_BUTTON_LABEL') }}</div>
              <div v-else-if="requestCategoryId === '4'">{{ $t('INSPECTION_AUCTION_REQUEST_CATEGORY_MYSELF_BUTTON_LABEL') }}</div>
            </div>
          </div>
          <div class="form-wrap">
            <p class="form-wrap-tit">{{ $t('INSPECTION_RESULT_ASSESSMENT_AMOUNT') }}</p>
            <div class="form-wrap-input">
              {{ requestResultData.assessment_amount | number2string('', '円') }}
            </div>
          </div>
          <div class="form-wrap" v-if="requestCategoryId === '4'">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_POPUP_BID_DATETIME_FIELD') }}</p>
            <div class="form-wrap-input">
              {{ auctionData.start_datetime }} ~ {{ auctionData.end_datetime }} から随時終了
            </div>
          </div>
          <div class="form-wrap" v-if="requestCategoryId === '4'">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_POPUP_EXHIBITION_NAME_FIELD') }}</p>
            <div class="form-wrap-input">
              {{ auctionData.exhibition_name }}
            </div>
          </div>
          <div class="form-wrap" v-if="requestCategoryId === '4'">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_POPUP_MAX_EXHIBITION_DATETIME_FIELD') }}</p>
            <div class="form-wrap-input" v-if="auctionData.extend_flag === 1">
              あり
              <div>※最大{{ auctionData.max_extend_datetime }}まで</div>
            </div>
            <div class="form-wrap-input" v-else>
              なし
            </div>
          </div>
          <div class="form-wrap">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_REQUEST_POST_CODE_LABEL') }}</p>
            <div class="form-wrap-input">
              {{ postCode }}
            </div>
          </div>
          <div class="form-wrap">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_REQUEST_ADDRESS_LABEL') }}</p>
            <div class="form-wrap-input">
              {{ address }}
            </div>
          </div>
          <div class="form-wrap">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_COMMENT') }}</p>
            <div class="form-wrap-input">
              <p style="white-space: pre-line">{{ comment }}</p>
            </div>
          </div>

          <div class="btn-form">
            <input
              type="button"
              class="btn-back"
              :value="$t('MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL')"
              @click="auctionRequestStore.completedFlag = false; auctionRequestStore.requestFlag = true"
            >
            <input
              type="button"
              :value="$t('MEMBER_REQUEST_CONFIRM_SEND_BUTTON_LABEL')"
              @click="requestItem"
            >
          </div>
        </div>
      </form>
    </div>

    <!-- 申請内容確認モーダル start -->
    <RequestComfirmDialog
      :isOpenDialog="isOpenDialog"
      :closeDialog="closeRequestComfirmDialog"
      :requestCategoryId="requestCategoryId"/>
    <!-- 申請内容確認モーダル end -->
  </div>
</template>

<script>
import CommonFilters from '@/common/commonFilters'
import inspectionAuctionRequest from '@/stores/inspectionAuctionRequest'
import useLoading from '@/stores/useLoading'
import useRequestResultPreview from '@/stores/useRequestResultPreview'
import {defineComponent} from '@vue/composition-api'
import RequestComfirmDialog from './parts/RequestComfirmDialog.vue'
export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const auctionRequestStore = inspectionAuctionRequest()
    const resultPreviewStore = useRequestResultPreview()

    return {
      loadingStore,
      auctionRequestStore,
      resultPreviewStore
    }
  },
  data() {
    return {
    }
  },
  components : {
    RequestComfirmDialog
  },
  mixins : [CommonFilters],
  props  : {
  },
  computed : {
    auctionData() {
      return this.auctionRequestStore.auctionData
    },
    postCode() {
      return this.auctionRequestStore.postCode
    },
    address() {
      return this.auctionRequestStore.address
    },
    comment() {
      return this.auctionRequestStore.comment
    },
    errors() {
      return this.auctionRequestStore.errors
    },
    isOpenDialog() {
      return this.auctionRequestStore.isOpenDialog
    },
    requestCategoryId() {
      return this.auctionRequestStore.requestCategoryId
    },
    requestResultData() {
      return this.resultPreviewStore.requestResultData
    },
  },
  methods : {
    requestItem() {
      this.auctionRequestStore.openRequestComfirmDialog()
    },
    closeRequestComfirmDialog() {
      this.auctionRequestStore.closeRequestComfirmDialog()
    },
  }
})
</script>

<style lang="scss" scoped>
.image-item {
  margin-top: 20px;
  margin-bottom: 20px;
}
form .form-wrap {
  align-items: start !important;
}
.selection-txt {
  margin-left: 10px;
}
.err-txt {
  font-size: 15px !important;
}
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>

<template>
<div class="d-flex flex-column justify-center align-end full-height">
  <p class="tit full-width">{{ type === 1 ? (image.label || '') : (image.title || '') }}</p>
  <div class="files-flex-list-box full-width">
    <div v-if="imgPreview" class="image">
      <v-img
        :src="imgPreview"
        @click.stop="openDialog(imageIndex)"
        :aspect-ratio="4/3"
        cover
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <div v-else>
      <label class="fileUploadBtn" tabindex="0">
        <a
          class="fileUploadBtn-link js-popup"
          @click="openDialog(imageIndex)"
        >
          <span class="only_pc">{{ $t('INSPECTION_IMAGE_UPLOAD_LABEL') }}</span>
          <span class="only_sp">{{ $t('INSPECTION_IMAGE_UPLOAD_LABEL_SP') }}</span>
        </a>
      </label>
      <!-- <label class="fileUploadBtn only_sp" tabindex="0">
        <label>
          <input :name="`file${imageIndex}`" type="file" required="">{{ $t('INSPECTION_IMAGE_UPLOAD_LABEL_SP') }}
        </label>
      </label>
      <div class="files-flex-list-box-comment only_sp">
        <p>{{ $t('INSPECTION_IMAGE_UPLOAD_TITLE') }}</p>
        <input type="text">
      </div> -->
    </div>
  </div>
</div>
</template>
<script>
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  components : {},
  props      : {
    type : {
      type : Number
    },
    imageIndex : {
      type : Number
    },
    isViewOnly : {
      type    : Boolean,
      default : false
    },
    image : {
      type    : Object,
      default : {}
    },
    openDialog : {
      type    : Function,
      default : Object
    },
    getPreviewData : {
      type    : Function,
      default : Object
    },
  },
  data() {
    return {
    }
  },
  computed : {
    imgPreview() {
      const src = this.isViewOnly ? (this.image.preview || require('@/assets/img/no_photo.jpg')) : this.image.preview
      // Console.log('this.image.preview: ', src)
      return src
    }
  },
  watch : {
    'image.key'() {
      this.getPreviewData(this.type, this.imageIndex, this.$i18n.locale)
    }
  },
  methods : {
  },
})
</script>
<style scoped lang="scss">
  .close-button {
    position: absolute;
    z-index: 1;
    margin: auto;
  }
  .action {
    display: block;
  }
  .files-flex-list-box .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .full-width {
    width: 100%;
  }

  @media only screen and (max-width: 767px) {
    #main .exhibit #entry-form .form-wrap .files-flex-list-box {
      height: 80px !important;
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>

import router from '@/router'
import _ from 'lodash'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useLoading from './useLoading'
const loadingStore = useLoading()

const initRequestResultData = {
  request_category_id   : null,
  request_category_name : null,
  judge_status          : null,
  judge_status_name     : null,
  assessment_amount     : null,
  judge_note            : null,
}

const useRequestResultPreview = defineStore('useRequestResultPreview', {
  state : () => {
    return {
      // Locale: 'ja',
      memberId          : null,
      requestResultData : _.cloneDeep(initRequestResultData),
    }
  },
  getters : {
  },
  actions : {
    resetData() {
      console.log('resetData')
      this.memberId = null
      this.requestResultData = _.cloneDeep(initRequestResultData)
    },
    getResult(itemRequestNo, locale = 'ja') {
      console.log('getResult')
      if (itemRequestNo) {
        loadingStore.setLoading(true)
        Methods.apiExcute('private/get-request-result-item', locale, {
          item_request_no : itemRequestNo
        }).then(data => {
          if (data && data.length > 0) {
            this.requestResultData = {
              request_category_id   : data[0].request_category_id,
              request_category_name : data[0].request_category_name,
              judge_status          : data[0].judge_status,
              judge_status_name     : data[0].judge_status_name,
              assessment_amount     : data[0].assessment_amount,
              judge_note            : data[0].judge_note
            }
          }
          loadingStore.setLoading(false)
        })
          .catch(error => {
            loadingStore.setLoading(false)
            Methods.parseHtmlResponseError(router, error)
          })
      } else {
        this.requestResultData = {}
      }
    },
  },
})

export default useRequestResultPreview

<template>
  <v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
    <v-card style="border-radius: 1rem;">
      <v-card-text class="border-bottom mt-5 popup popup-fileup" style="display: block;">
        <div class="popup-wrap small">
          <a class="popup-close js-close">
            <img @click.stop="closeDialog" src="@/assets/img/common/ic_close.svg" alt="">
          </a>
          <div v-if="errors && errors.message" class="popup-content">
            <div class="popup-content-form">
              <p class="text-center text-red">{{ errors.message }}<br></p>
            </div>
            <div class="popup-content-form-btnWrap">
              <v-btn @click.stop="closeDialog" color="red" class="white--text">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL")}}</v-btn>
            </div>
          </div>
          <div v-else class="popup-content">
            <div class="popup-content-form">
              <slot></slot>
            </div>
            <div class="popup-content-form-btnWrap">
              <v-btn v-if="isShowBtnCancel" @click.stop="closeDialog" color="red" class="white--text">{{ txtCancel || $t("INSPECTION_STEP2_DIALOG_CANCEL")}}</v-btn>
              <v-btn v-if="isShowBtnOk" @click="request" color="#364A81" class="white--text">{{ txtOk || $t("INSPECTION_STEP2_DIALOG_OK")}}</v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  </template>

<script>
import CommonFilters from '@/common/commonFilters'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    return {
      loadingStore,
    }
  },
  components : {},
  mixins     : [CommonFilters],
  props      : {
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
    txtOk : {
      type    : String,
      default : null
    },
    txtCancel : {
      type    : String,
      default : null
    },
    isShowBtnOk : {
      type    : Boolean,
      default : true
    },
    isShowBtnCancel : {
      type    : Boolean,
      default : true
    }
  },
  data() {
    return {
      errors : {}
    }
  },
  methods : {
    request() {
      this.errors = {}
      this.$emit('action')
    },
    closeDialog() {
      this.$emit('close')
    },
  },
})
</script>
  <style lang="scss" scoped>
    .popup-content-form-btnWrap button {
      margin: 0 20px;
    }
    .text-red {
      color: red;
    }
  </style>
  <style scoped lang="scss" src="@/assets/scss/style.scss"></style>
